.text {
  margin: 0;
}

.font_merriweather {
  font-family: 'Merriweather', 'Lato', sans-serif;
}

.font_lato {
  font-family: 'Lato', 'Merriweather', sans-serif;
}

.italic {
  font-style: italic;
}

.text--size-0 {
  font-weight: normal;
  font-size: 9px;
  line-height: 13px;
}

.text--size-1 {
  font-size: 10px;
  line-height: 1.2;
}

.text--size-2 {
  font-size: 12px;
  line-height: 1.2;
}

.text--size-3 {
  font-size: 14px;
  line-height: 1.2;
}

.text--size-4 {
  font-size: 16px;
  line-height: 1.2;
}

.text--size-5 {
  font-size: 18px;
  line-height: 1.2;
}

.uppercase {
  text-transform: uppercase;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
