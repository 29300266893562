/* ------------------------------------------------------ Muli ----------------------------------------*/

@font-face {
  font-family: 'Muli';
  src: local('Muli Blackitalic'), local('Muli-Blackitalic'),
    url('../fonts/muli-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/muli-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/muli-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/muli-v16-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/muli-v16-latin-regular.svg#Muli') format('svg'); /* Legacy iOS */
}
/* ------------------------------------------------------ Roboto ----------------------------------------*/

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(roboto-light.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(roboto-medium.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(roboto-regular.woff2) format('woff2');
}
