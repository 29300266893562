.button {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 130px;
  height: 40px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  z-index: 1;
  font-family: 'Lato', 'Merriweather', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(14px, 0.012vw, 16px);
  line-height: 19px;
}

/* Block */
.block {
  width: 100%;
  height: 40px;
}

.button__icon:not(:empty) {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.button:disabled {
  opacity: 0.8;
}

.button:disabled:hover {
  background-color: #11142c;
  cursor: auto;
}

/* Default */
.default {
  background-color: #11142c;
  color: #fff;
}

.default:hover {
  background-color: rgb(17 20 44 / 80%);
}

.default:active {
  background-color: #11142c;
}

/* Outline */
.outline {
  border: 1px solid #707280;
  color: #707280;
  background-color: #fff;
}

/* Secondary */
.secondary {
  background: #0dc29b;
  color: #fff;
}

.secondary:hover {
  background-color: #34c5a7;
}

.secondary:active {
  background: #0dc29b;
}

.secondary .button__ripple {
  background-color: #12705c !important;
}

/* Dashed */
.dashed {
  background-color: #fff;
  color: #11142c;
  border: 1px dashed #707280;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
}

.dashed:hover {
  background: #fcfcfc;
}

/* icon */
.icon {
  min-width: 176px;
  height: 40px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #fff;
  justify-content: left;
  padding: 11px 20px 11px 20px;
  color: #11142c;
}

.icon:hover {
  background-color: #fcfcfc;
}

/* text */
.text {
  background-color: transparent;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
}

.text:hover {
  text-decoration: underline;
}

/* Size */
.sm {
  height: 36px;
  min-width: 140px;
}

.icon__sm:not(:empty) {
  width: 14px;
  height: 14px;
}

.inline {
  min-width: initial;
}

/* Right icon */
.right {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}
