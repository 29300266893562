.alert {
  border-radius: 4px;
  padding: 12px 27px 11px;
  overflow: visible !important;
}

.alert :global(.ant-notification-notice-with-icon .ant-notification-notice-message) {
  margin-left: 34px;
}

.alert :global(.ant-notification-notice-with-icon) {
  display: flex;
  align-items: center;
}

.alert :global(.ant-notification-notice-close) {
  position: absolute;
  top: -13.5px;
  right: -13.4px;
}

.alert :global(.ant-notification-notice-icon) {
  margin-left: 0;
}

.alert :global(.ant-notification-notice-message) {
  margin-bottom: 0;
}

.info {
  background-color: #fff;
}

.error {
  background-color: #ea2139 !important;
}

.success {
  background-color: #0dc29b !important;
}

.warn {
  background-color: #ffa740 !important;
}
