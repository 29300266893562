.title {
  margin: 0;
}

.currentColor {
  color: currentColor;
}

.italic {
  font-style: italic;
}

.title.level1 {
  font-family: 'Merriweather', 'Lato', sans-serif;
  font-size: 22px;
  line-height: 1.2;
}

.title.level2 {
  font-family: 'Lato', 'Merriweather', sans-serif;
  font-size: 22px;
  line-height: 1.2;
}

.title.level3 {
  font-family: 'Lato', 'Merriweather', sans-serif;
  font-size: 20px;
  line-height: 1.2;
}

.title.level4 {
  font-family: 'Merriweather', 'Lato', sans-serif;
  font-size: 18px;
  line-height: 1.2;
}

.title.level5 {
  font-family: 'Merriweather', 'Lato', sans-serif;
  font-size: 16px;
  line-height: 1.2;
}

.title.level6 {
  font-family: 'Merriweather', 'Lato', sans-serif;
  font-size: 14px;
  line-height: 1.2;
}
