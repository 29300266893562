.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.sm {
  width: 342px;
}

.md {
  width: 574px;
}

.lg {
  width: 768px;
}

@media (max-width: 768px) {
  .lg {
    width: 574px;
  }
}

@media (max-width: 574px) {
  .lg {
    max-width: 342px;
  }
}

@media (max-width: 574px) {
  .md {
    width: 342px;
  }
}

.footer {
  width: 100%;
  height: 60px;
  background: #f8fbff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 13px 0 13px;
  display: flex;
  justify-content: center;
}

.header {
  width: 100%;
  padding: 32px 47px 20px 32px;
}

.title {
  max-width: 231px;
  margin-bottom: 15px;
}

.body {
  flex: 1;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 36px;
  right: 32px;
  cursor: pointer;
}

.cancel {
  margin-right: 18px;
}
