.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: currentColor;
  z-index: -1;
  opacity: 0.2;
}

.animate {
  animation: ripple 600ms linear;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.animate__forwards {
  animation: ripple-forwards 600ms linear forwards;
  transition: all 600ms;
}

@keyframes ripple-forwards {
  to {
    transform: scale(4);
  }
}
